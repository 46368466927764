import axios from '@/common/js/request'

// PC工单管理(非居民工单列表)-管明杨
export function getworkData(data) {
  const getworkData = axios({
    url: '/data/dataList',
    method: 'get',
    params: data
  })
  return getworkData
}
// PC工单管理(任务编号下拉)-管明杨
export function taskCodeList(data) {
  const taskCodeList = axios({
    url: '/data/taskCodeList',
    method: 'get',
    params: data
  })
  return taskCodeList
}
// PC工单管理(片区下拉)-管明杨
export function taskAddrList(data) {
  const taskAddrList = axios({
    url: '/data/taskAddrList',
    method: 'get',
    params: data
  })
  return taskAddrList
}
// PC工单管理(安检员下拉)-管明杨
export function taskInspectorList(data) {
  const taskInspectorList = axios({
    url: '/data/taskInspectorList',
    method: 'get',
    params: data
  })
  return taskInspectorList
}
// PC工单管理(采集模板下拉)-管明杨
export function taskTplIdList(data) {
  const taskTplIdList = axios({
    url: '/data/taskTplIdList',
    method: 'get',
    params: data
  })
  return taskTplIdList
}
// PC工单管理(查看详情)-管明杨
export function dataParticulars(data) {
  const dataParticulars = axios({
    url: '/data/dataParticulars',
    method: 'get',
    params: data
  })
  return dataParticulars
}
// PC工单管理(导出excel)-管明杨
export function dataListExcel(data) {
  const dataListExcel = axios({
    url: '/data/dataListExcel',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob', //改变接收的值类型
    params: data,
    isloading: true
  })
  return dataListExcel
}

// PC工单管理(居民工单片区下拉)
export function getAddrDropDown(data) {
  const getAddrDropDown = axios({
    url: '/arumArchive/addrDropDown',
    method: 'get',
    params: data
  })
  return getAddrDropDown
}

// 部门下拉
export function getdeptDropDown(data) {
  const getdeptDropDown = axios({
    url: '/arumAddr/deptDropDown',
    method: 'get',
    params: data
  })
  return getdeptDropDown
}



// PC工单管理(居民工单列表)
export function getResidentWorkData(data) {
  const getResidentWorkData = axios({
    url: '/arumData/dataPageList',
    method: 'get',
    params: data
  })
  return getResidentWorkData
}
// 民用历史任务(安检信息详情)
export function getHistorySecurityUser(data) {
  const getHistorySecurityUser = axios({
    url: '/arumData/historyDataDetails',
    method: 'get',
    params: data
  })
  return getHistorySecurityUser
}
export function getSecurityUser(data) {
  const getSecurityUser = axios({
    url: '/arumData/dataDetails',
    method: 'get',
    params: data
  })
  return getSecurityUser
}
// 民用工单安检记录
export function getdataCheckRecord(data) {
  const getdataCheckRecord = axios({
    url: '/arumData/dataCheckRecord',
    method: 'get',
    params: data
  })
  return getdataCheckRecord
}


// PC工单管理(居民工单批量审核)
export function dataBatchAudit(data) {
  const dataBatchAudit = axios({
    url: '/arumData/dataBatchAudit',
    method: 'post',
    data: data
  })
  return dataBatchAudit
}
// 民用工单置顶-取消置顶
export function getDataTop(data) {
  const getDataTop = axios({
    url: '/arumData/dataTop',
    method: 'get',
    params: data
  })
  return getDataTop
}
// 安检内容
export function getfindScuItemTreeEcho(data) {
  const getfindScuItemTreeEcho = axios({
    url: '/arumSet/findScuItemTreeEcho',
    method: 'get',
    params: data
  })
  return getfindScuItemTreeEcho
}

// 问题管理隐患内容树
export function findScuItemTreeEchoHaveStop(data) {
  const findScuItemTreeEchoHaveStop = axios({
    url: '/arumSet/findScuItemTreeEchoHaveStop',
    method: 'get',
    params: data
  })
  return findScuItemTreeEchoHaveStop
}

// PC民用工单管理(安检员下拉)-管明杨
export function inspectorDropDown(data) {
  const inspectorDropDown = axios({
    url: '/AaumArchive/inspectorDropDown',
    method: 'get',
    params: data
  })
  return inspectorDropDown
}

export function addrDropDown1(data) {
  const addrDropDown1 = axios({
    url: '/arumData/addrDropDown1',
    method: 'get',
    params: data
  })
  return addrDropDown1
}
export function inspectorDropDown1(data) {
  const inspectorDropDown1 = axios({
    url: '/arumData/inspectorDropDown1',
    method: 'get',
    params: data,
    isloading: true
  })
  return inspectorDropDown1
}

// 问题管理分页列表
export function getproblemPageList(data) {
  const getproblemPageList = axios({
    url: '/arumData/problemPageList',
    method: 'get',
    params: data
  })
  return getproblemPageList
}
// 问题管理批量处理
export function getproblemBatchHandle(data) {
  const getproblemBatchHandle = axios({
    url: '/arumData/problemBatchHandle',
    method: 'post',
    data: data
  })
  return getproblemBatchHandle
}
// 问题管理导出
export function getproblemExportExcel(data) {
  const getproblemExportExcel = axios({
    url: '/arumData/problemExportExcel',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    isloading: true,
    responseType: 'blob' //改变接收的值类型
  })

  return getproblemExportExcel
}

// PC异常工单(片区下拉)-管明杨
export function abnormalAddrList(data) {
  const abnormalAddrList = axios({
    url: '/data/abnormalAddrList',
    method: 'get',
    params: data
  })
  return abnormalAddrList
}

// PC异常工单(安检员下拉)-管明杨
export function abnormalInspectorList(data) {
  const abnormalInspectorList = axios({
    url: '/data/abnormalInspectorList',
    method: 'get',
    params: data
  })
  return abnormalInspectorList
}
// PC异常工单(任务编号)-管明杨
export function abnormalCodeList(data) {
  const abnormalCodeList = axios({
    url: '/data/abnormalCodeList',
    method: 'get',
    params: data
  })
  return abnormalCodeList
}
// PC异常工单(模板下拉)-管明杨
export function abnormalTplIdList(data) {
  const abnormalTplIdList = axios({
    url: '/data/abnormalTplIdList',
    method: 'get',
    params: data
  })
  return abnormalTplIdList
}
// 修改整改方式-姜恩源
export function updateWoodenAbarbeitung(data) {
  const updateWoodenAbarbeitung = axios({
    url: '/arumData/updateWoodenAbarbeitung',
    method: 'get',
    params: data
  })
  return updateWoodenAbarbeitung
}
// 居民用户工单导出
export function dataExportExcel(data) {
  const dataExportExcel = axios({
    url: '/arumData/dataExportExcel',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob', //改变接收的值类型
    isloading:true
  })
  return dataExportExcel
}
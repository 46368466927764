<template>
	<div>
		<!-- 编辑记录 -->
		<div class="content1">
			<div class="all">
				<!-- <el-button size="small" @click="backtrack">返回</el-button> -->
				<div class="peopleUserInfo">
					<el-form
						:model="ruleForm"
						:rules="rules"
						ref="ruleForm"
						size="small"
						label-width="100px"
						class="demo-ruleForm"
					>
						<div class="peopleUserList">
							<div class="title">基本信息</div>
							<div>
								<el-row :gutter="24">
									<el-col :span="12">
										<el-form-item
											label="户主姓名:"
											prop="residentName"
										>
											<el-input
												placeholder="请输入"
												v-model="ruleForm.residentName"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item
											label="户主电话:"
										>
											<!-- prop="mobile" -->
											<el-input
												placeholder="请输入"
												v-model="ruleForm.mobile"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item
											label="身份证号:"
											prop="idnumber"
										>
											<el-input
												placeholder="请输入"
												v-model="ruleForm.idnumber"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item
											label="用户分类:"
											prop="userType"
										>
											<el-select
												style="width: 100%"
												v-model="ruleForm.userType"
												clearable
												filterable
												placeholder="请选择用户分类"
											>
												<el-option
													v-for="item in userTypes"
													:key="item.id"
													:label="item.name"
													:value="item.id"
												>
												</el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item
											label="片区:"
											prop="addrs"
										>
											<el-cascader
												style="width: 100%"
												clearable
												ref="refDep"
												separator='-'
												v-model="ruleForm.addrs"
												:options="taskAddrdata"
												filterable
												@change="handleDep"
												:props="{
													value: 'trueId',
													label: 'name',
													disabled: 'disable'
												}"
											></el-cascader>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item
											label="地址:"
											prop="address"
										>
											<el-input
												placeholder="请输入"
												v-model="ruleForm.address"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
						</div>
						<div class="peopleUserList">
							<div class="title">安检信息</div>
							<div>
								<el-row :gutter="24">
									<el-col :span="12">
										<el-form-item label="安检联系人:">
											<el-input
												placeholder="请输入安检联系人"
												v-model="ruleForm.contactName"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="联系电话:">
											<el-input
												placeholder="请输入联系电话"
												v-model="ruleForm.contactMobile"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="是否为租户:">
											<el-radio-group
												v-model="ruleForm.islessee"
											>
												<el-radio :label="1"
													>是</el-radio
												>
												<el-radio :label="0"
													>否</el-radio
												>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
						</div>
						<div class="peopleUserList">
							<div class="title">表具信息</div>
							<div>
								<el-row :gutter="24">
									<el-col :span="12">
										<el-form-item label="表编号:">
											<el-input
												placeholder="请输入"
												v-model="ruleForm.sheetSteel"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="燃气表类型:">
											<el-select
												style="width: 100%"
												v-model="ruleForm.tableType"
												clearable
												filterable
												placeholder="请选择燃气表类型"
											>
												<el-option
													v-for="item in tableTypeData"
													:key="item.id"
													:label="item.name"
													:value="item.id"
												>
												</el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="表向:">
											<el-select
												style="width: 100%"
												v-model="
													ruleForm.tableDirection
												"
												clearable
												filterable
												placeholder="请选择表向"
											>
												<el-option
													v-for="item in tableDirectionData"
													:key="item.id"
													:label="item.name"
													:value="item.id"
												>
												</el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="燃气表厂家:">
											<el-select
												style="width: 100%"
												v-model="ruleForm.tableVender"
												clearable
												filterable
												placeholder="请选择燃气表厂家"
											>
												<el-option
													v-for="item in tableVenderData"
													:key="item.id"
													:label="item.name"
													:value="item.id"
												>
												</el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="表封号:">
											<el-input
												placeholder="请输入"
												v-model="ruleForm.tableTitles"
											></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="开户日期:">
											<el-date-picker
												style="width: 100%"
												v-model="ruleForm.openDate"
                        type="datetime"
												value-format="yyyy-MM-dd HH:mm:ss"
												placeholder="选择日期"
											>
											</el-date-picker>
										</el-form-item>
									</el-col>
									<el-col :span="24">
										<el-form-item label="所接炉具:">
											<el-input
												placeholder="请输入"
												v-model="ruleForm.receiveStove"
											></el-input>
										</el-form-item>
									</el-col>
									<!-- <el-col :span="12">
										<el-form-item label="对接系统:">
											<el-radio-group
												v-model="ruleForm.tableSystem"
											>
												<el-radio :label="1"
													>卡表系统</el-radio
												>
												<el-radio :label="2"
													>营收系统</el-radio
												>
											</el-radio-group>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="识别号:">
											<el-input
												placeholder="请输入"
												v-model="
													ruleForm.identifyNumber
												"
											></el-input>
										</el-form-item>
									</el-col> -->
									<el-col :span="24">
										<el-form-item label="备注:">
											<el-input
												placeholder="请输入"
												v-model="ruleForm.remark"
											></el-input>
										</el-form-item>
									</el-col>
								</el-row>
							</div>
						</div>
					</el-form>
					<div class="btns">
						<!-- <el-button
							size="mini"
							style="height: 30px; margin-top: 5px"
							@click="backtrack"
							>取消</el-button
						> -->
						<el-button
							size="mini"
							type="primary"
							@click="save()"
							style="height: 30px; margin-top: 5px"
							>确定</el-button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import { getAddrDropDown } from "@/RequestPort/workorder/maintenRecord";
	import { selectDictListByPid } from "@/RequestPort/device/deviceList";
	import { civilUserInsertOrUpdate } from "@/RequestPort/induction";
	export default {
		name: "peopleUserEdit",
		data() {
			return {
				ruleForm: {
					address:'',
					sheetSteel:'',
					tableType:'',
					tableDirection:'',
					tableVender:'',
					userType:''
				},
				rules: {
					residentName: [
						{
							required: true,
							message: "请输入户主姓名",
							trigger: "blur",
						},
						{ max: 25, message: "超出最大限制", trigger: "change" },
					],
					mobile: [
						{
							required: true,
							message: "请输入户主电话",
							trigger: "blur",
						}
					],
					idnumber: [
						{
							message: "请输入正确的身份证号",
							trigger: ["change"],
							pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
						},
					],
					userType: [
						{ required: true, message: "请选择", trigger: "change" },
					],
					address: [
						{ required: true, message: "请选择", trigger: "change" },
					],
					addrs: [
						{ required: true, message: "请选择", trigger: "change" },
					],
				},
				taskAddrdata: [],
				userTypes: [],
				tableDirectionData: [], //表向
				tableTypeData: [], //燃气表类型
				tableVenderData: [], //燃气表厂家
			};
		},
		created() {
			if (this.$route.query.obj) {
				let arr = [];
				let ruleForm = JSON.parse(this.$route.query.obj);
				if (ruleForm.addrId) arr.push(ruleForm.addrId);
				if (ruleForm.addrsId) arr.push(ruleForm.addrsId);
				if (ruleForm.addrssId) arr.push(ruleForm.addrssId);
				this.$set(ruleForm, "addrs", arr);
				this.ruleForm = {...this.ruleForm,...ruleForm};
				console.log(this.ruleForm)
				this.ruleForm.tableVender = ruleForm.tableVender?ruleForm.tableVender+'':'';
				this.ruleForm.tableDirection = ruleForm.tableDirection?ruleForm.tableDirection+'':'';
				this.ruleForm.tableType = ruleForm.tableType?ruleForm.tableType+'':'';
			}
			// 片区下拉
			this.gettaskAddrList();
			// 用户分类
			this.getuserTypeDropDown();
			// 表向
			this.getTableDirectio();
			// 燃气表类型
			this.geTtableType();
			// 燃气表厂家
			this.getTableVender();
			this.handleDep(1)
		},
		methods: {
			handleDep(val){
				setTimeout(() => {
					let node = this.$refs.refDep.getCheckedNodes()[0]				
					if(node && val != 1){
						this.ruleForm.address = node.parent.parent.data.name+'-'+node.parent.data.name+'-'+node.data.name
					}				
				}, 250);
			},
			/**
			 * 返回
			 */
			backtrack() {
				this.$router.back(-1);
			},
			/**
			 * 片区下拉
			 */
			gettaskAddrList() {
				getAddrDropDown().then((res) => {
					res.data.forEach(item=>{
						if(item.children){
						item.disable = false
						item.children.forEach(el => {
							if(el.children){
								el.disable = false
							} else {
								el.disable = true
							}
						})
						}
						else {
						item.disable = true
						}
					})
					this.taskAddrdata = res.data
				});
			},
			/***
			 * 用户分类
			 */
			getuserTypeDropDown() {
				selectDictListByPid({ pid: 732 }).then((res) => {
					this.userTypes = res.data;
				});
			},
			/***
			 * 表向
			 */
			getTableDirectio() {
				selectDictListByPid({ pid: 707 }).then((res) => {
					this.tableDirectionData = res.data;
				});
			},
			/***
			 * 燃气表类型
			 */
			geTtableType() {
				selectDictListByPid({ pid: 702 }).then((res) => {
					this.tableTypeData = res.data;
				});
			},
			/***
			 * 燃气表厂家
			 */
			getTableVender() {
				selectDictListByPid({ pid: 710 }).then((res) => {
					this.tableVenderData = res.data;
				});
			},
			restSearch() {
				this.ruleForm = {};
			},
			save() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (this.ruleForm.addrs.length >= 2) {
							this.ruleForm.addrId = this.ruleForm.addrs[0];
							this.ruleForm.addrsId = this.ruleForm.addrs[1];
							this.ruleForm.addrssId = this.ruleForm.addrs[2];
						}
						civilUserInsertOrUpdate(this.ruleForm).then((res) => {
							this.$message.success(res.data);
							// this.backtrack();
							this.$emit('backtrack')
						});
					}
				});
			},
			/**
			 * 弹窗过来的取消
			 */
			clace(){
				if( this.$refs.ruleForm) {
					this.$refs.ruleForm.resetFields()
				}
				this.ruleForm = {};
			},
			/**
			 * 弹窗打开
			 */
			openEdit(obj){
				let arr = [];
				let ruleForm = JSON.parse(obj);
				if (ruleForm.addrId) arr.push(ruleForm.addrId);
				if (ruleForm.addrsId) arr.push(ruleForm.addrsId);
				if (ruleForm.addrssId) arr.push(ruleForm.addrssId);
				this.$set(ruleForm, "addrs", arr);
				this.ruleForm = {...this.ruleForm,...ruleForm};
				console.log(this.ruleForm)
				this.ruleForm.tableVender = ruleForm.tableVender?ruleForm.tableVender+'':'';
				this.ruleForm.tableDirection = ruleForm.tableDirection?ruleForm.tableDirection+'':'';
				this.ruleForm.tableType = ruleForm.tableType?ruleForm.tableType+'':'';
			}
		},
	};
</script>

<style lang="less" scoped>
	// .content1 {
	// 	position: relative;
	// 	margin: 10px;
	// }
	.all {
		// padding: 10px;
		border-radius: 5px;
		background-color: #ffffff;
		// padding-bottom: 30px;
		overflow: auto;
		box-sizing: border-box;
		// height: calc(100vh - 91px);
		height:auto;
		.btns {
			// position: absolute;
			// width: 99%;
			// left: 0;
			// bottom: 20px;
			text-align: center;
		}
		.peopleUserInfo {
			// max-width: 1000px;
			// margin-top: 10px;
			// padding-left: 20px;
			width: 95%;
			.peopleUserList {
				.title {
					font-size: 18px;
					font-weight: bolder;
					margin-bottom: 10px;
				}
			}
		}
	}
</style>